<template>
  <div class="costing-selection">
    <form-group
      :validator="$v.value[jobLabelAttribute]"
      :messages="errorMessages"
      v-if="jobLabelEnabled"
      label="Job label"
      class="job-label"
      v-bind="formGroupConfig"
    >
      <label-select
        slot-scope="{ attrs, listeners }"
        v-bind="attrs"
        v-on="listeners"
        v-model="value[jobLabelAttribute]"
        :multiple="false"
        labelType="work"
        :disabled="disabled"
      />
    </form-group>
    <form-group
      :validator="$v.value[jobCategoryAttribute]"
      :messages="errorMessages"
      v-if="jobCategoryEnabled && sortedJobCategories.length > 0"
      label="Job category"
      class="job-category"
      v-bind="formGroupConfig"
    >
      <key-multiselect
        slot-scope="{ attrs, listeners }"
        v-bind="attrs"
        v-on="listeners"
        v-model="value[jobCategoryAttribute]"
        :options="sortedJobCategories"
        label="name"
        track-by="id"
        select-label=""
        deselect-label=""
        :disabled="disabled"
      />
    </form-group>
    <form-group
      :validator="$v.value[jobAttribute]"
      :messages="errorMessages"
      label="Job"
      class="job"
      v-bind="formGroupConfig"
    >
      <remote-multiselect
        slot-scope="{ attrs, listeners }"
        v-bind="attrs"
        v-on="listeners"
        ref="job"
        v-model="value[jobAttribute]"
        :id="uniqueIdentifier"
        label="name"
        track-by="id"
        select-label=""
        deselect-label=""
        :serviceFetch="fetchJobs"
        :disabled="disabled"
        @fullValueChange="job = $event"
      />
    </form-group>
    <form-group
      :validator="$v.value[jobPhaseAttribute]"
      :messages="errorMessages"
      label="Job phase"
      class="job-phase"
      v-bind="formGroupConfig"
      v-if="jobPhaseEnabled"
    >
      <remote-multiselect
        slot-scope="{ attrs, listeners }"
        v-bind="attrs"
        v-on="listeners"
        ref="jobPhase"
        v-model="value[jobPhaseAttribute]"
        :id="uniqueIdentifier"
        label="name"
        track-by="id"
        select-label=""
        deselect-label=""
        :serviceFetch="fetchJobPhases"
        :disabled="disabled"
        @fullValueChange="jobPhase = $event"
      />
    </form-group>
    <form-group
      :validator="$v.value[costCodeAttribute]"
      :messages="errorMessages"
      label="Cost code"
      class="cost-code"
      v-bind="formGroupConfig"
      v-if="costCodeEnabled"
    >
      <remote-multiselect
        slot-scope="{ attrs, listeners }"
        v-bind="attrs"
        v-on="listeners"
        ref="costCode"
        v-model="value[costCodeAttribute]"
        :id="uniqueIdentifier"
        label="name"
        track-by="id"
        select-label=""
        deselect-label=""
        :disabled="disabled"
        :serviceFetch="fetchCostCodes"
        @fullValueChange="costCode = $event"
      />
    </form-group>
    <b-form-group v-if="enablePartialCosting">
      <b-form-checkbox v-model="value[costingIsPartialAttribute]" :disabled="disabled">
        Costing Selection is Partial
        <help-text-icon>
          If checked, then the worker will be prompted on the time clock to select
          any fields which were not locked here, as long as that field has values to select.
          <template v-if="showMinVersion">
            <br><br>
            This feature requires time clock mobile app version 2.11 or later for job phase and cost code partial lock.
            The job label lock requires version 3.1 or later.
          </template>
        </help-text-icon>
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import JobService from '../costing/services/JobService'
import JobPhaseService from '../costing/services/JobPhaseService'
import CostCodeService from '../costing/services/CostCodeService'
import KeyMultiselect from '@/components/KeyMultiselect'
import LabelSelect from '@/views/settings/organization/LabelSelect'
import RemoteMultiselect from '@/components/RemoteMultiselect'
import ChildValidation from '@/mixins/ChildValidation'
import _ from 'lodash'
import HelpTextIcon from '@/components/HelpTextIcon'
import { forceArray } from '@/utils/misc'

export default {
  name: 'CostingSelection',
  components: {
    HelpTextIcon,
    KeyMultiselect,
    LabelSelect,
    RemoteMultiselect
  },
  mixins: [ChildValidation],
  props: {
    value: Object,
    // For bulk actions, we are querying for common values.
    worker: [Number, Array],
    deviceOrgUnit: [Number, Array],
    orgUnit: [Number, Array],
    department: [Number, Array],
    userLabels: Array,
    customFieldValues: Array,
    jobLabelAttribute: {
      type: String,
      default: 'jobLabel'
    },
    jobCategoryAttribute: {
      type: String,
      default: 'jobCategory'
    },
    jobAttribute: {
      type: String,
      default: 'job'
    },
    jobPhaseAttribute: {
      type: String,
      default: 'jobPhase'
    },
    costCodeAttribute: {
      type: String,
      default: 'costCode'
    },
    costingIsPartialAttribute: {
      type: String,
      default: 'costingIsPartial'
    },
    enablePartialCosting: {
      type: Boolean,
      default: true
    },
    enableJobCategory: {
      type: Boolean,
      default: true
    },
    enableJobRequiredValidation: {
      type: Boolean,
      default: false
    },
    formGroupConfig: Object,
    invalidMessage: {
      type: String,
      default: 'This selection is not valid.'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showMinVersion: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // These values each hold entire option object,
      // for filtering and validation.
      job: null,
      jobPhase: null,
      costCode: null
    }
  },
  computed: {
    ...mapState(['jobRequired', 'organizationId']),
    ...mapGetters(['enhancedCostingEnabled']),
    ...mapGetters({
      jobCategories: 'jobCategories/sortedItems'
    }),
    jobCategoryEnabled () {
      return this.enableJobCategory && this.$store.getters['jobCategoryEnabled']
    },
    sortedJobCategories () {
      return this.jobCategories('name')
    },
    partialCostingChecked () {
      return this.enablePartialCosting && !!this.value[this.costingIsPartialAttribute]
    },
    jobLabelEnabled () {
      return this.partialCostingChecked && !this.job
    },
    jobPhaseEnabled () {
      if (!this.enhancedCostingEnabled) return
      return (!!this.job && !!this.job.jobPhaseMode) || this.partialCostingChecked
    },
    costCodeEnabled () {
      if (!this.enhancedCostingEnabled) return
      return (!!this.job && !!this.job.costCodeMode) || this.partialCostingChecked
    },
    uniqueIdentifier () {
      // TODO: Do job phase and cost code fields need a different identifier that includes job id?
      return `${this.value.id}-${this.value.employee}-${forceArray(this.deviceOrgUnit || []).join('-')}`
    },
    errorMessages () {
      return {
        isValid: this.invalidMessage
      }
    },
    customFieldValueParams () {
      return (this.customFieldValues || [])
        .filter(value => ['bool', 'choice'].includes(value.type))
        .flatMap(value => forceArray(value.value).map(v => `${value.field}:${v}`))
    }
  },
  watch: {
    'value.jobCategory': function (value) {
      if (!value && this.job) return // probably a job without a category was just selected
      this.$refs.job.trySearch()
      if (!this.jobCategoryEnabled) return
      if (this.jobPhaseEnabled && this.$refs.jobPhase) this.$refs.jobPhase.trySearch()
    },
    job (job) {
      this.$emit('jobChanged', job)
      if (!this.enhancedCostingEnabled) return
      if (job) {
        if (this.jobCategoryEnabled) {
          this.value[this.jobCategoryAttribute] = job.jobCategory
        }
        // TODO: If jobPhaseMode off, shouldn't we also remove all job phase options?
        // TODO: And if jobPhaseMode off, we might need to clear job phases anyway
        // TODO: because those are the job phases for the previous job.
        // TODO: And when parent id changes we may need to also clear everything,
        // TODO: because org unit / department may have changed.
        // TODO: Similarly with cost codes.
        if (!job.jobPhaseMode) this.value[this.jobPhaseAttribute] = null
        if (!job.costCodeMode) this.value[this.costCodeAttribute] = null
        this.value[this.jobLabelAttribute] = null
      } else {
        this.value[this.jobPhaseAttribute] = null
        this.value[this.costCodeAttribute] = null
      }
    },
    department () {
      // refetch jobs since filter changed
      this.$refs.job.trySearch()
    },
    deviceOrgUnit () {
      // refetch jobs since filter changed
      this.$refs.job.trySearch()
    },
    partialCostingChecked (value) {
      if (!this.jobPhaseEnabled) {
        this.value[this.jobPhaseAttribute] = null
      }
      if (!this.costCodeEnabled) {
        this.value[this.costCodeAttribute] = null
      }
    },
    jobLabelEnabled (value) {
      if (!value) {
        this.value[this.jobLabelAttribute] = null
      }
    }
  },
  methods: {
    fetchJobs (searchText, value, limit) {
      return JobService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          id: value,
          limit,
          requirePerms: true,
          worker: this.worker,
          deviceOrgUnit: this.deviceOrgUnit,
          orgUnit: this.orgUnit,
          department: this.department,
          userLabel: this.userLabels,
          customFieldValues: this.customFieldValueParams,
          jobCategory: this.value[this.jobCategoryAttribute]
        })
        .then(data => _.get(data, 'results', []))
    },
    fetchJobPhases (searchText, value, limit) {
      return JobPhaseService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          id: value,
          limit,
          requirePerms: true,
          worker: this.worker,
          deviceOrgUnit: this.deviceOrgUnit,
          orgUnit: this.orgUnit,
          department: this.department,
          userLabel: this.userLabels,
          customFieldValues: this.customFieldValueParams,
          job: this.value[this.jobAttribute],
          workLabel: this.job?.labels,
          jobCategory: this.value[this.jobCategoryAttribute]
        })
        .then(data => _.get(data, 'results', []))
    },
    fetchCostCodes (searchText, value, limit) {
      return CostCodeService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          id: value,
          limit,
          worker: this.worker,
          deviceOrgUnit: this.deviceOrgUnit,
          orgUnit: this.orgUnit,
          department: this.department,
          userLabel: this.userLabels,
          customFieldValues: this.customFieldValueParams,
          // TODO: Temporary disable requirePerms and work filtering for one particular org until we resolve query branch explosion.
          // TODO: Probably need to wait until we upgrade our backend to using native IN queries.
          ...(this.organizationId === 5544977541234688 ? {} : {
            job: this.value[this.jobAttribute],
            jobPhase: this.value[this.jobPhaseAttribute],
            requirePerms: true,
            workLabel: (this.job?.labels || []).concat(this.jobPhase?.labels || [])
          })
        })
        .then(data => _.get(data, 'results', []))
    }
  },
  mounted () {
    if (this.jobCategoryEnabled) {
      this.$store.dispatch('jobCategories/load')
    }
  },
  validations () {
    return {
      // TODO: Try to do some validation when costing is partial.
      value: {
        [this.jobLabelAttribute]: {
          // It doesn't seem we need any validation for label.
        },
        [this.jobCategoryAttribute]: {
          isValid: value => {
            if (!this.enhancedCostingEnabled) return true
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (!this.job) return true
            if (!this.job.jobCategory) return true
            if (this.job.jobCategory === value) return true
            return false
          }
        },
        [this.jobAttribute]: {
          isValid: value => {
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (value) return true
            if (this.enableJobRequiredValidation && this.jobRequired) return false
            if (!this.enhancedCostingEnabled) return true
            if (!this.jobCategoryEnabled || !this.value[this.jobCategoryAttribute]) return true
            return false
          }
        },
        [this.jobPhaseAttribute]: {
          isValid: value => {
            if (!this.enhancedCostingEnabled) return true
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (!this.job) return !value
            if (!value && this.job.jobPhaseMode === 'require') return false
            if (!this.job.jobPhaseMode) return !value
            if (!this.jobPhase) return true // I think this case is transient during loading
            if (value && this.jobPhase.job && this.jobPhase.job !== this.value[this.jobAttribute]) return false
            if (value && this.jobPhase.jobCategory && this.jobPhase.jobCategory !== this.value[this.jobCategoryAttribute]) return false
            return true
          }
        },
        [this.costCodeAttribute]: {
          isValid: value => {
            if (!this.enhancedCostingEnabled) return true
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (!this.job) return !value
            if (!value && this.job.costCodeMode === 'require') return false
            if (!this.job.costCodeMode) return !value
            if (!this.costCode) return true // I think this case is transient during loading
            if (value && this.costCode.job && this.costCode.job !== this.value[this.jobAttribute]) return false
            return true
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.costing-selection {
  .form-group {
    margin: .5rem;
  }
  .multiselect {
    max-width: 20rem;
  }
}
</style>
